import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const orgServices = {
    findMetaSupplier() {
        return http.get(`${API_ROOT}/provider/organizations/meta-supplier`).then(resp => {
            return resp
        })
    },
    //Provider Internal Actions
    findInternalActions(eventType) {
        return http.get(`${API_ROOT}/provider/organizations/internal-actions-meta/${eventType}`).then(resp => {
            return resp
        })
    },
    saveInternalActions(eventType, payload) {
        return http.post(`${API_ROOT}/provider/organizations/save-actions-meta/${eventType}`,payload).then(resp => {
            return resp
        })
    },
    getAutoNotifySetting() {
        return http.get(`${API_ROOT}/provider/organizations/AutoNotify`).then(resp => {
            return resp
        })
    },
}

export default orgServices
